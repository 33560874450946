// Media objects
// Source: http://stubbornella.org/content/?p=497
// --------------------------------------------------


// Common styles
// -------------------------

// Clear the floats
.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

// Proper spacing between instances of .media
.media,
.media .media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}

// For images and videos, set to block
.media-object {
  display: block;
}

// Reset margins on headings for tighter default spacing
.media-heading {
  margin: 0 0 5px;
}


// Media image alignment
// -------------------------

.media {
  > .pull-left {
    margin-right: 10px;
  }
  > .pull-right {
    margin-left: 10px;
  }
}


// Media list variation
// -------------------------

// Undo default ul/ol styles
.media-list {
  padding-left: 0;
  list-style: none;
}
